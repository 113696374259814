import Postcode from '@/models/Postcode'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    postCodeDetails: {}
  },

  mutations: {
    setPostCodeList(state, postCodes) {
      each(postCodes, postCode => {
        const exist = find(state.list, { id: postCode.id })
        if (!exist) {
          state.list.push(new Postcode(postCode))
        }
      })
    },

    clearPostCodeList(state) {
      state.list = []
    },

    setPostCodeListMeta(state, meta) {
      state.listMeta = meta
    },

    setPostCodeDetails(state, postCode) {
      if (postCode instanceof Postcode) {
        state.postCodeDetails = postCode
      } else {
        state.postCodeDetails = new Postcode(postCode)
      }
    },

    clearPostCodeDetails(state) {
      state.postCodeDetails = null
    }
  },

  actions: {
    async getPostCodes({ commit }, params) {
      const query = Postcode.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setPostCodeList', res.data)
      commit('setPostCodeListMeta', res.meta)
    },

    async getPostCodeDetails({ commit }, id) {
      const { data } = await Postcode.find(id)
      commit('setPostCodeDetails', data)
    },

    async updatePostCodeDetails({ commit }, formData) {
      const { data } = await Api.put(`postcodes/${formData.id}`, formData)

      commit('setPostCodeDetails', data.data)
    },

    async deletePostCode({ commit }, id) {
      await Api.delete(`postcodes/${id}`)
    },

    async restorePostCode({ commit }, id) {
      await Api.put(`postcodes/${id}/restore`)
    }
  }
}
