import QuestionCategory from '@/models/QuestionCategory'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    categoryDetails: null
  },

  mutations: {
    setCategoryList(state, categories) {
      each(categories, category => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new QuestionCategory(category))
        }
      })
    },

    clearCategoryList(state) {
      state.list = []
    },

    setCategoryListMeta(state, meta) {
      state.listMeta = meta
    },

    setCategoryDetails(state, category) {
      if (category instanceof QuestionCategory) {
        state.categoryDetails = category
      } else {
        state.categoryDetails = new QuestionCategory(category)
      }
    },

    clearCategoryDetails(state) {
      state.categoryDetails = null
    }
  },

  actions: {
    async getCategories({ commit }, params) {
      const query = QuestionCategory.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setCategoryList', res.data)
      commit('setCategoryListMeta', res.meta)
    },

    async getCategoryDetails({ commit }, id) {
      const { data } = await Api.get(`questions/categories/${id}`)
      commit('setCategoryDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      const { data } = await Api.put(
        `questions/categories/${formData.id}`,
        formData
      )
      commit('setCategoryDetails', data.data)
    }
  }
}
