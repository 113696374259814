import State from '@/utils/rawStates/State'

const rawVoucherForm = () => {
  return new State({
    id: null,
    code: null,
    patient_id: null,
    status: null,
    date_expiry: null
  })
}

export default rawVoucherForm
