export default {
  path: '/notifications',
  name: 'notifications',
  redirect: { name: 'notifications.motivation' },
  component: () => import(/* webpackChunkName: "motivation" */ '@/views/Home'),
  children: [
    {
      path: 'motivation',
      name: 'notifications.motivation',
      component: () =>
        import(
          /* webpackChunkName: "notifications.motivation" */ '@/views/Home/Notifications/Motivation'
        )
    },
    {
      path: 'diagnostic-reminder',
      name: 'notifications.diagnostic-reminder',
      component: () =>
        import(
          /* webpackChunkName: "notifications.diagnostic-reminder" */ '@/views/Home/Notifications/DiagnosticReminder'
        )
    },
    {
      path: 'reassessment',
      name: 'notifications.reassessment',
      component: () =>
        import(
          /* webpackChunkName: "notifications.reassessment" */ '@/views/Home/Notifications/Reassessment'
        )
    }
  ]
}
