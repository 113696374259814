import Exercise from '@/models/Exercise'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    exerciseDetails: null
  },

  mutations: {
    setExerciseList(state, exercises) {
      each(exercises, exercise => {
        const exist = find(state.list, { id: exercise.id })
        if (!exist) {
          state.list.push(new Exercise(exercise))
        }
      })
    },

    clearExerciseList(state) {
      state.list = []
    },

    setExerciseListMeta(state, meta) {
      state.listMeta = meta
    },

    setExerciseDetails(state, exercise) {
      if (exercise instanceof Exercise) {
        state.exerciseDetails = exercise
      } else {
        state.exerciseDetails = new Exercise(exercise)
      }
    },

    clearExerciseDetails(state) {
      state.exerciseDetails = null
    },

    setExerciseImage(state, image) {
      state.exerciseDetails.image = image
    }
  },

  actions: {
    async getExercises({ commit }, params) {
      const query = Exercise.page(params.page || 1)

      if (params.search) {
        query.where('name', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.sortBy && params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setExerciseList', res.data)
      commit('setExerciseListMeta', res.meta)
    },

    async getExerciseDetails({ commit }, id) {
      const { data } = await Api.get(`exercises/${id}`)
      commit('setExerciseDetails', data.data)
    },

    async updateExercise({ commit }, formData) {
      const { data } = await Api.put(`exercises/${formData.id}`, formData)
      commit('setExerciseDetails', data.data)
    },

    async deleteExercise({ commit }, id) {
      await Api.delete(`exercises/${id}`)
    },

    async changeImage({ commit }, formData) {
      const { data } = await Api.post(
        `exercises/${formData.get('exercise_id')}/image`,
        formData
      )
      commit('setExerciseImage', data.data.image)
    },

    async removeImage({ commit }, id) {
      await Api.delete(`exercises/${id}/image`)
    },

    async deleteStep({ commit }, data) {
      await Api.delete(
        `exercises/${data.exerciseId}/steps/${data.exerciseStepId}`
      )
    },

    async updateStep({ commit }, formData) {
      await Api.put(
        `exercises/${formData.exerciseId}/steps/${formData.id}`,
        formData
      )
    },

    async updateExerciseVideo({ commit }, formData) {
      const { data } = await Api.post(
        `exercises/${formData.get('id')}/video`,
        formData
      )

      commit('setExerciseDetails', data.data)
    },

    async deleteExerciseVideo({ commit }, id) {
      await Api.delete(`exercises/${id}/video`)
    },

    async sortSteps({ commit }, formData) {
      const { data } = await Api.post(`exercises/${formData.id}/sort`, formData)

      commit('setExerciseDetails', data.data)
    }
  }
}
