import Voucher from '@/models/Voucher'
import { each, find } from 'lodash'
import Api from '@/services/api'
import Form from '@/utils/form'
import { rawVoucherForm } from '@/utils/rawStates'
export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    voucherDetails: null,
    voucherForm: rawVoucherForm(),
    voucherGeneratedCode: null
  },

  mutations: {
    setVoucherList(state, vouchers) {
      each(vouchers, voucher => {
        const exist = find(state.list, { id: voucher.id })
        if (!exist) {
          state.list.push(new Voucher(voucher))
        }
      })
    },

    clearVoucherList(state) {
      state.list = []
    },

    setVoucherListMeta(state, meta) {
      state.listMeta = meta
    },

    setVoucherDetails(state, voucher) {
      if (voucher instanceof Voucher) {
        state.voucherDetails = voucher
      } else {
        state.voucherDetails = new Voucher(voucher)
      }
    },

    setVoucherForm(state, voucher) {
      state.voucherForm = new Form({
        id: voucher.id,
        patient_id: voucher.patient.id,
        date_expiry: voucher.date_expiry,
        code: voucher.code,
        status: voucher.status
      })
    },

    setCode(state, voucher) {
      state.voucherGeneratedCode = voucher.code
    },

    setCodeForm(state, code) {
      state.voucherForm.code = code
    },

    clearVoucherForm(state) {
      state.voucherForm = rawVoucherForm({})
    },

    clearVoucherDetails(state) {
      state.voucherDetails = null
    }
  },

  actions: {
    async getVouchers({ commit }, params) {
      const query = Voucher.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }
      const res = await query.params({ limit: params.itemsPerPage }).get()
      commit('setVoucherList', res.data)
      commit('setVoucherListMeta', res.meta)
    },

    async generateCode({ commit }) {
      const { data } = await Api.get(`vouchers/generate-code`)
      commit('setCode', data.data)
    },

    async getVoucherDetails({ commit }, id) {
      const { data } = await Voucher.find(id)
      commit('setVoucherDetails', data)
      this.commit('user/setUserList', [data.patient])
      this.commit('user/setDefaultUserId', data.patient.id)
      commit('setVoucherForm', data)
    },

    async updateVoucherDetails({ commit }, formData) {
      const { data } = await Api.put(`vouchers/${formData.id}`, formData)
      commit('setVoucherDetails', data.data)
    },

    async deleteVoucher({ commit }, id) {
      await Api.delete(`vouchers/${id}`)
      commit('clearVoucherDetails')
    }
  }
}
