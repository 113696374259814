import MealRestriction from '@/models/MealRestriction'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    mealRestrictionDetails: null
  },

  mutations: {
    setMealRestrictionList(state, mealRestrictions) {
      each(mealRestrictions, mealRestriction => {
        const exist = find(state.list, { id: mealRestriction.id })
        if (!exist) {
          state.list.push(new MealRestriction(mealRestriction))
        }
      })
    },

    clearMealRestrictionList(state) {
      state.list = []
    },

    setMealRestrictionListMeta(state, meta) {
      state.listMeta = meta
    },

    setMealRestrictionDetails(state, mealRestriction) {
      if (mealRestriction instanceof MealRestriction) {
        state.mealRestrictionDetails = mealRestriction
      } else {
        state.mealRestrictionDetails = new MealRestriction(mealRestriction)
      }
    },

    clearMealRestrictionDetails(state) {
      state.mealRestrictionDetails = null
    }
  },

  actions: {
    async getMealRestrictions({ commit }, params) {
      const query = MealRestriction.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setMealRestrictionList', res.data)
      commit('setMealRestrictionListMeta', res.meta)
    },

    async getMealRestrictionDetails({ commit }, id) {
      const { data } = await Api.get(`meals/restrictions/${id}`)
      commit('setMealRestrictionDetails', data.data)
    },

    async updateMealRestrictionDetails({ commit }, formData) {
      const { data } = await Api.put(
        `meals/restrictions/${formData.id}`,
        formData
      )
      commit('setMealRestrictionDetails', data.data)
    },

    async deleteMealRestriction({ commit }, id) {
      await Api.delete(`meals/restrictions/${id}`)
    }
  }
}
