import Questionnaire from '@/models/Questionnaire'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    questionnaireDetails: null
  },

  mutations: {
    setQuestionnaireList(state, questionnaires) {
      each(questionnaires, questionnaire => {
        const exist = find(state.list, { id: questionnaire.id })
        if (!exist) {
          state.list.push(new Questionnaire(questionnaire))
        }
      })
    },

    clearQuestionnaireList(state) {
      state.list = []
    },

    setQuestionnaireListMeta(state, meta) {
      state.listMeta = meta
    },

    setQuestionnaireDetails(state, questionnaire) {
      if (questionnaire instanceof Questionnaire) {
        state.questionnaireDetails = questionnaire
      } else {
        state.questionnaireDetails = new Questionnaire({
          ...questionnaire,
          choices: questionnaire.choices.map(choice => ({
            ...choice,
            popup: {
              title: choice.popup && choice.popup.title,
              message: choice.popup && choice.popup.message
            },
            isImageLoading: false
          }))
        })
      }
    },

    clearQuestionnaireDetails(state) {
      state.questionnaireDetails = null
    },

    setQuestionnaireCustomScreenImage(state, image) {
      state.questionnaireDetails.custom_screen_next_image = image
    },

    setQuestionnaireChoicePopupImage(state, data) {
      state.questionnaireDetails.choices.find(
        choice => choice.id === parseInt(data.id)
      ).popup_image = data.image
    },

    setQuestionnaireChoiceIsImageLoading(state, data) {
      state.questionnaireDetails.choices.find(
        choice => choice.id === parseInt(data.id)
      ).isImageLoading = data.flag
    }
  },

  actions: {
    async getQuestionnaires({ commit }, params) {
      const query = Questionnaire.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.includes) {
        each(params.includes, (value, key) => {
          if (value !== null) query.include(value)
        })
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setQuestionnaireList', res.data)
      commit('setQuestionnaireListMeta', res.meta)
    },

    async getQuestionnaireDetails({ commit }, id) {
      const { data } = await Api.get(`questions/questionnaires/${id}`)
      commit('setQuestionnaireDetails', data.data)
    },

    async updateQuestionnaire({ commit }, formData) {
      const { data } = await Api.put(
        `questions/questionnaires/${formData.id}`,
        formData
      )
      commit('setQuestionnaireDetails', data.data)
    },

    async updateCustomScreenNextImage({ commit }, formData) {
      const { data } = await Api.post(
        `questions/questionnaires/${formData.get('id')}/custom-screen-image`,
        formData
      )
      commit('setQuestionnaireCustomScreenImage', data.data)
    },

    async removeCustomScreenNextImage({ commit }, id) {
      await Api.delete(`questions/questionnaires/${id}/custom-screen-image`)
      commit('setQuestionnaireCustomScreenImage', null)
    },

    async deleteQuestionnaire({ commit }, id) {
      await Api.delete(`questions/questionnaires/${id}`)
      commit('clearQuestionnaireDetails')
    },

    async updateChoice({ commit }, formData) {
      await Api.put(
        `questions/questionnaires/${formData.questionnaire_id}/choices/${formData.id}`,
        formData
      )
    },

    async deleteChoice({ commit }, data) {
      await Api.delete(
        `questions/questionnaires/${data.questionnaire_id}/choices/${data.id}`
      )
    },

    async updateChoicePopupImage({ commit }, formData) {
      commit('setQuestionnaireChoiceIsImageLoading', {
        id: formData.get('id'),
        flag: true
      })

      const { data } = await Api.post(
        `questions/questionnaires/${formData.get(
          'questionnaire_id'
        )}/choices/${formData.get('id')}/image`,
        formData
      )

      commit('setQuestionnaireChoicePopupImage', {
        id: formData.get('id'),
        image: data.data
      })

      commit('setQuestionnaireChoiceIsImageLoading', {
        id: formData.get('id'),
        flag: false
      })
    },

    async deleteChoicePopupImage({ commit }, data) {
      Api.delete(
        `questions/questionnaires/${data.questionnaire_id}/choices/${data.id}/image`
      )

      commit('setQuestionnaireChoicePopupImage', {
        id: data.id,
        image: null
      })
    }
  }
}
