import ReassessmentQuestionnaire from '@/models/ReassessmentQuestionnaire'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    reassessmentQuestionnaireDetails: {
      custom_screen_next: {}
    }
  },

  mutations: {
    setReassessmentQuestionnaireList(state, reassessmentQuestionnaires) {
      each(reassessmentQuestionnaires, reassessmentQuestionnaire => {
        const exist = find(state.list, { id: reassessmentQuestionnaire.id })
        if (!exist) {
          state.list.push(
            new ReassessmentQuestionnaire(reassessmentQuestionnaire)
          )
        }
      })
    },

    clearReassessmentQuestionnaireList(state) {
      state.list = []
    },

    setReassessmentQuestionnaireListMeta(state, meta) {
      state.listMeta = meta
    },

    setReassessmentQuestionnaireDetails(state, reassessmentQuestionnaire) {
      reassessmentQuestionnaire = {
        ...reassessmentQuestionnaire,
        custom_screen_next: {
          button_label:
            reassessmentQuestionnaire.custom_screen_next &&
            reassessmentQuestionnaire.custom_screen_next.button_label,
          description:
            reassessmentQuestionnaire.custom_screen_next &&
            reassessmentQuestionnaire.custom_screen_next.description,
          title:
            reassessmentQuestionnaire.custom_screen_next &&
            reassessmentQuestionnaire.custom_screen_next.title
        }
      }

      if (reassessmentQuestionnaire instanceof ReassessmentQuestionnaire) {
        state.reassessmentQuestionnaireDetails = reassessmentQuestionnaire
      } else {
        state.reassessmentQuestionnaireDetails = new ReassessmentQuestionnaire(
          reassessmentQuestionnaire
        )
      }
    },

    clearReassessmentQuestionnaireDetails(state) {
      state.reassessmentQuestionnaireDetails = null
    }
  },

  actions: {
    async getReassessmentQuestionnaires({ commit }, params) {
      const query = ReassessmentQuestionnaire.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setReassessmentQuestionnaireList', res.data)
      commit('setReassessmentQuestionnaireListMeta', res.meta)
    },

    async getReassessmentQuestionnaireDetails({ commit }, id) {
      const { data } = await ReassessmentQuestionnaire.find(id)
      commit('setReassessmentQuestionnaireDetails', data)
    },

    async updateReassessmentQuestionnaireDetails({ commit }, formData) {
      const { data } = await Api.put(
        `reassessment-questionnaires/${formData.id}`,
        formData
      )
      commit('setReassessmentQuestionnaireDetails', data.data)
    },

    async deleteReassessmentQuestionnaire({ commit }, id) {
      await Api.delete(`reassessment-questionnaires/${id}`)
      commit('clearReassessmentQuestionnaireDetails')
    },

    async deleteReassessmentQuestionnaireChoice({ commit }, formData) {
      await Api.delete(
        `reassessment-questionnaires/${formData.id}/choices/${formData.choiceId}`
      )
    },

    async sortChoices({ commit }, formData) {
      const { data } = await Api.post(
        `reassessment-questionnaires/${formData.id}/choices/sort`,
        formData
      )

      commit('setReassessmentQuestionnaireDetails', data.data)
    },

    async createChoice({ commit }, formData) {
      const { data } = await Api.post(
        `reassessment-questionnaires/${formData.id}/choices`,
        formData
      )

      commit('setReassessmentQuestionnaireDetails', data.data)
    },

    async changeImage({ commit }, formData) {
      const { data } = await Api.post(
        `reassessment-questionnaires/${formData.get('id')}/image`,
        formData
      )
      commit('setReassessmentQuestionnaireDetails', data.data)
    },

    async removeImage({ commit }, id) {
      await Api.delete(`reassessment-questionnaires/${id}/image`)
    }
  }
}
