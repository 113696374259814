import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'
import questionnaires from './questionnaires'
import notifications from './notifications'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    settings,
    notifications,
    questionnaires,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox')
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users')
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            )
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content')
        },
        // {
        //   path: 'notification',
        //   name: 'notification',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
        //     )
        // },
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/Home/Reports')
        },
        {
          path: 'meals',
          name: 'meals',
          component: () =>
            import(/* webpackChunkName: "meals" */ '@/views/Home/Meals')
        },
        {
          path: 'meals/:id',
          name: 'meals.details',
          component: () =>
            import(
              /* webpackChunkName: "meals" */ '@/views/Home/Meals/MealDetails'
            )
        },
        {
          path: 'meal-restrictions',
          name: 'meal.restrictions',
          component: () =>
            import(
              /* webpackChunkName: "meal-restrictions" */ '@/views/Home/MealRestrictions'
            )
        },
        {
          path: 'meal-restrictions/:id',
          name: 'meal.restrictions.details',
          component: () =>
            import(
              /* webpackChunkName: "meal-restrictions-details" */ '@/views/Home/MealRestrictions/EditMealRestriction'
            )
        },
        {
          path: 'exercises',
          name: 'exercises',
          component: () =>
            import(/* webpackChunkName: "exercises" */ '@/views/Home/Exercises')
        },
        {
          path: 'exercises/:id',
          name: 'exercises.details',
          component: () =>
            import(
              /* webpackChunkName: "exercises" */ '@/views/Home/Exercises/ExerciseDetails'
            )
        },
        {
          path: 'plans',
          name: 'plans',
          component: () =>
            import(/* webpackChunkName: "plans" */ '@/views/Home/Plans')
        },
        {
          path: 'plans/:id',
          name: 'plans.details',
          component: () =>
            import(
              /* webpackChunkName: "plans" */ '@/views/Home/Plans/PlanDetails'
            )
        },
        {
          path: 'vouchers',
          name: 'vouchers',
          component: () =>
            import(/* webpackChunkName: "plans" */ '@/views/Home/Vouchers')
        },
        {
          path: 'vouchers/:id',
          name: 'vouchers.details',
          component: () =>
            import(
              /* webpackChunkName: "plans" */ '@/views/Home/Vouchers/VoucherDetails'
            )
        },
        {
          path: 'postcodes',
          name: 'postcodes',
          component: () =>
            import(/* webpackChunkName: "postcodes" */ '@/views/Home/Postcode')
        },
        {
          path: 'modules',
          name: 'modules',
          component: () =>
            import(/* webpackChunkName: "modules" */ '@/views/Home/Modules')
        },
        {
          path: 'modules/:id',
          name: 'modules.details',
          component: () =>
            import(
              /* webpackChunkName: "modules" */ '@/views/Home/Modules/ModuleDetails'
            )
        }
      ]
    }
  ]
})
