import Module from '@/models/Module'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    moduleDetails: null
  },

  mutations: {
    setModuleList(state, modules) {
      each(modules, module => {
        const exist = find(state.list, { id: module.id })
        if (!exist) {
          state.list.push(new Module(module))
        }
      })
    },

    clearModuleList(state) {
      state.list = []
    },

    setModuleListMeta(state, meta) {
      state.listMeta = meta
    },

    setModuleDetails(state, module) {
      if (module instanceof Module) {
        state.moduleDetails = module
      } else {
        state.moduleDetails = new Module(module)
      }
    },

    clearModuleDetails(state) {
      state.moduleDetails = null
    },

    setModuleImage(state, image) {
      state.moduleDetails.image = image
    },

    addArticleList(state, article) {
      state.moduleDetails.articles.push(article)
    },

    deleteModuleArticle(state, index) {
      state.moduleDetails.articles.splice(index)
    },

    addQuizList(state, quiz) {
      state.moduleDetails.quizzes.push(quiz)
    },

    deleteModuleQuiz(state, index) {
      state.moduleDetails.quizzes.splice(index)
    }
  },

  actions: {
    async getModules({ commit }, params) {
      const query = Module.page(params.page || 1)

      if (params.query) {
        query.where('search', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setModuleList', res.data)
      commit('setModuleListMeta', res.meta)
    },

    async getModuleDetails({ commit }, id) {
      const { data } = await Api.get(`modules/${id}`)
      commit('setModuleDetails', data.data)
    },

    async updateModule({ commit }, formData) {
      const { data } = await Api.put(`modules/${formData.id}`, formData)
      commit('setModuleDetails', data.data)
    },

    async deleteModule({ commit }, id) {
      await Api.delete(`modules/${id}`)
    },

    async changeImage({ commit }, formData) {
      const { data } = await Api.post(
        `modules/${formData.get('module_id')}/image`,
        formData
      )
      commit('setModuleImage', data.data)
    },

    async removeImage({ commit }, id) {
      await Api.delete(`modules/${id}/image`)
    },

    async addArticle({ commit }, id) {
      const { data } = await Api.post(`modules/${id}/articles`)
      commit('addArticleList', data.data)
    },

    async updateModuleArticle({ commit }, formData) {
      await Api.put(`articles/${formData.id}`, formData)
    },

    async deleteArticle({ commit }, formData) {
      await Api.delete(`articles/${formData.id}`)
      commit('deleteModuleArticle', formData.index)
    },

    async addQuiz({ commit }, { id, formData }) {
      const { data } = await Api.post(`modules/${id}/quizzes`, formData)
      commit('addQuizList', data.data)
    },

    async updateQuiz({ commit }, formData) {
      await Api.put(`quizzes/${formData.id}`, formData)
    },

    async deleteQuiz({ commit }, { id, index }) {
      await Api.delete(`quizzes/${id}`)
      commit('deleteModuleQuiz', index)
    },

    async updateVideo({ commit }, formData) {
      const { data } = await Api.post(
        `modules/${formData.get('id')}/video`,
        formData
      )

      commit('setModuleDetails', data.data)
    },

    async deleteVideo({ commit }, id) {
      await Api.delete(`modules/${id}/video`)
    }
  }
}
