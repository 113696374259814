import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'
import questionnaireCategory from './modules/questionnaires/category'
import questionnaires from './modules/questionnaires'
import meal from './modules/meal'
import exercise from './modules/exercise'
import restriction from './modules/restriction'
import plan from './modules/plan'
import voucher from './modules/voucher'
import reassessment from './modules/questionnaires/reassessment'
import postcode from './modules/postcode'
import module from './modules/module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    report,
    versionControl,
    questionnaireCategory,
    questionnaires,
    meal,
    exercise,
    restriction,
    plan,
    voucher,
    reassessment,
    postcode,
    module
  },
  state: {
    controls: {
      showDrawer: true
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    }
  }
})
