import Meal from '@/models/Meal'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    mealDetails: null
  },

  mutations: {
    setMealList(state, meals) {
      each(meals, meal => {
        const exist = find(state.list, { id: meal.id })
        if (!exist) {
          state.list.push(new Meal(meal))
        }
      })
    },

    clearMealList(state) {
      state.list = []
    },

    setMealListMeta(state, meta) {
      state.listMeta = meta
    },

    setMealDetails(state, meal) {
      if (meal instanceof Meal) {
        state.mealDetails = meal
      } else {
        state.mealDetails = new Meal(meal)
      }
    },

    clearMealDetails(state) {
      state.mealDetails = null
    },

    setMealImage(state, image) {
      state.mealDetails.image = image
    }
  },

  actions: {
    async getMeals({ commit }, params) {
      const query = Meal.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.filters) {
        each(params.filters, (value, key) => {
          if (value !== null) query.where(key, value)
        })
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setMealList', res.data)
      commit('setMealListMeta', res.meta)
    },

    async getMealDetails({ commit }, id) {
      const { data } = await Api.get(`meals/${id}`)
      commit('setMealDetails', data.data)
    },

    async updateMeal({ commit }, formData) {
      const { data } = await Api.put(`meals/${formData.id}`, formData)
      commit('setMealDetails', data.data)
    },

    async deleteMeal({ commit }, id) {
      await Api.delete(`meals/${id}`)
    },

    async changeImage({ commit }, formData) {
      const { data } = await Api.post(
        `meals/${formData.get('meal_id')}/image`,
        formData
      )
      commit('setMealImage', data.data)
    },

    async removeImage({ commit }, id) {
      await Api.delete(`meals/${id}/image`)
    }
  }
}
