import Plan from '@/models/Plan'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    planDetails: null
  },

  mutations: {
    setPlanList(state, plans) {
      each(plans, plan => {
        const exist = find(state.list, { id: plan.id })
        if (!exist) {
          state.list.push(new Plan(plan))
        }
      })
    },

    clearPlanList(state) {
      state.list = []
    },

    setPlanListMeta(state, meta) {
      state.listMeta = meta
    },

    setPlanDetails(state, plan) {
      if (plan instanceof Plan) {
        state.planDetails = plan
      } else {
        state.planDetails = new Plan(plan)
      }
    },

    clearPlanDetails(state) {
      state.planDetails = null
    }
  },

  actions: {
    async getPlans({ commit }, params) {
      const query = Plan.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.sortBy.length > 0) {
        query.orderBy(
          params.sortDesc[0] ? `-${params.sortBy[0]}` : `${params.sortBy[0]}`
        )
      }

      const res = await query.params({ limit: params.itemsPerPage }).get()

      commit('setPlanList', res.data)
      commit('setPlanListMeta', res.meta)
    },

    async getPlanDetails({ commit }, id) {
      const { data } = await Plan.find(id)
      commit('setPlanDetails', data)
    },

    async updatePlanDetails({ commit }, formData) {
      const { data } = await Api.put(`plans/${formData.id}`, formData)
      commit('setPlanDetails', data.data)
    },

    async deletePlan({ commit }, id) {
      await Api.delete(`plans/${id}`)
      commit('clearPlanDetails')
    }
  }
}
