import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Meal extends Model {
  resource() {
    return 'meals/restrictions'
  }

  get addedDate() {
    return dayjs
      .utc(this.created_at)
      .local()
      .format('DD/MM/YYYY hh:mm A')
  }
}
