export default {
  path: '/questionnaires',
  name: 'questionnaires',
  redirect: { name: 'questionnaires.onboarding' },
  component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
  children: [
    {
      path: 'onboarding',
      name: 'questionnaires.onboarding',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.onboarding" */ '@/views/Home/Questionnaires/Onboarding'
        )
    },
    {
      path: 'onboarding/:id',
      name: 'questionnaires.onboarding.details',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.onboarding.details" */ '@/views/Home/Questionnaires/Onboarding/OnboardingDetails'
        )
    },
    {
      path: 'categories',
      name: 'questionnaires.categories',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.categories" */ '@/views/Home/Questionnaires/Categories'
        )
    },
    {
      path: 'categories/:id',
      name: 'questionnaires.categories.details',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.categories.details" */ '@/views/Home/Questionnaires/Categories/CategoryDetails'
        )
    },
    {
      path: 'reassessment',
      name: 'questionnaires.reassessment',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.reassessment" */ '@/views/Home/Questionnaires/Reassessment/index'
        )
    },
    {
      path: 'reassessment/:id',
      name: 'questionnaires.reassessment.details',
      component: () =>
        import(
          /* webpackChunkName: "questionnaires.reassessment.details" */ '@/views/Home/Questionnaires/Reassessment/ReassessmentDetails'
        )
    }
  ]
}
