import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Voucher extends Model {
  resource() {
    return 'vouchers'
  }

  get addedDate() {
    return dayjs
      .utc(this.created_at)
      .local()
      .format('DD/MM/YYYY hh:mm A')
  }

  get dateExpiry() {
    return dayjs
      .utc(this.date_expiry)
      .local()
      .format('DD/MM/YYYY hh:mm A')
  }

  get patientName() {
    return this.patient ? this.patient.full_name : ''
  }
}
